import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"

class PrecisionSystems extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Precision Systems" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-precisionsystems inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                </h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://precisionsystemshq.com/" title="" target="_blank">
                                <img src="/img/clients/precision-icons-app.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">PRECISION SYSTEMS.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link cursor-pointer" href="https://precisionsystemshq.com/" title="PRECISION SYSTEMS" target="_blank">precisionsystemshq.com</a>
                            </span>
                            <span className="project__detail">Year 2022</span>
                        </p>
                    </aside>
                    <p>
                        <br />
                        <h1 className="article__heading m-top">Donnez de la puissance à votre entreprise grâce à nos solutions de gestion de points de vente.</h1>
                        <p className="article__para">
                            <b>Plus qu’un système d’encaissement… <br />Precision Systems, le véritable nouveau cerveau de votre entreprise !</b>
                            
                        </p>
                    </p>
                    {/* <p className="article__para">
                        A travers une interface fluide et personnalisable, vous disposerez en temps réel de toutes les informations liées à votre commerce.
                    </p>
                    
                    <p className="article__para">
                        La caisse Precision Systems permet l’enregistrement des commande au comptoir via iPad et offre une grande flexibilité.
                    </p>
                    <p className="article__para">
                        Ajouter, modifier ou supprimer un article déjà encaissé n’est plus un problème.
                    </p>
                    <p className="article__para">
                        La solution permet de contrôler le tiroir de caisse et garantit également l’impression de tickets de caisse instantanée et paramétrables. 
                    </p>
                    <p className="article__para">
                        Grâce à l’intelligence du système, la gestion des stocks devient un jeu d’enfant ! Celui-ci permet le calcul en temps réel du stock théorique et assure la gestion des pertes ainsi qu’un suivi méticuleux des dates de péremption.
                    </p>
                    <p className="article__para">
                        Étant connecté à l'ensemble de l'écosystème Precision Systems, le caissier peut émettre des demandes de réapprovisionnement, visualiser les livraisons programmées du jour et confirmer la réception de la marchandise.
                    </p>
                    <p className="article__para">
                        Quant à la recette, la caisse gère avec précision le fond de caisse, l’espèce encaissée, les paiements par chèques et les transactions TPE. 
                    </p> */}
                </div>

               


                <p className="article__belt">
                        <h2 className="article__heading titles-ps">LOGICIEL <strong className="titles-ps-strong">DE CAISSE</strong> </h2>
                        <p class="article__para"> 
                            Une solution complète, basée sur le cloud et dotée de toutes les fonctionnalités
                            nécessaires au métier de commerçant pour vous faire progresser !</p>
                        
                        <img className="article__belt-image" src="/img/projects/precisionsystems/logiciel-de-caisse_fr.png"
                            alt="mobile-apps" />
                    </p>
                    <p className="article__para">
                        L'interface permet aux gestionnaires : <br />
                            - Créez et gérez votre catalogue <br />
                            - Générez des QR codes pour l’identification rapide de vos produits et un meilleur suivi <br />
                            - Constituez les paniers de vos clients en sélectionnant les produits depuis votre écran de vente ou en les scannant grâce au CamScanner intégré <br />
                            - Planifiez vos commandes et assurez le suivi de leur production et de leur livraison<br />
                            - Ajoutez des notes et des commentaires à vos ventes <br />
                            - Constituez des dossiers de vente en attachant des pièces jointes et des justificatifs et bénéficiez d’un archivage et un d’historique complet<br />
                            - Accordez des remises et des réductions partielles ou totales<br />
                            - Procédez à l’encaissement et acceptez les modes de paiement de votre choix (espèce,TPE, chèques, tickets restos…)<br />
                            - Émettez des tickets de caisse imprimés ou électroniques<br />

                    </p>
                    <p className="article__belt">
                    <h2 className="article__heading titles-ps">ENCAISSEMENT & <strong className="titles-ps-strong">PAIEMENT </strong></h2>
                    <p class="article__para">Offrez un meilleur service en équipant vos employés d’outils de pointe</p>
                    <img className="article__belt-image" src="/img/projects/precisionsystems/logiciel-de-caisse2_fr.png"
                        alt="mobile-apps" />
                </p>


                <div className="article__body article__body--has-extra bg-red" style={{ paddingBottom: "23px"}}>

                    <aside className="article__extra image-rapport">
                        <p className="article__para inuit-u-p0">
                            
                                <img src="/img/projects/precisionsystems/dashboard_fr.png" alt="" className="project__logo-psin" />
                        
                        </p>

                        <p className="article__para inuit-u-p0 ">
                            <span className="project__detail">PRECISION SYSTEMS.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link cursor-pointer" href="https://precisionsystemshq.com/" title="PRECISION SYSTEMS" target="_blank">Rapports En temps réel</a>
                            </span>
                            
                        </p>
                    </aside>

                    <p>
                        <br />
                        <h1 className="article__heading titles-ps">Rapports  <strong className="titles-ps-strong">En temps réel</strong></h1>
                        <p className="article__para">
                            <b> Pour des décisions commerciales   <br /> plus intelligentes ...  </b>
                           
                        </p>
                    </p>
                    <p className="article__para">
                    Obtenez, en remote, les rapports de votre activité <br />
                    en direct. Des rapports adaptés à vos besoins, <br />
                    conçus par des experts pour vous aider à suivre<br />
                    les performances de vos points de vente.<br />
                    Prenez des décisions intelligentes et augmentez <br />
                    votre rentabilité !


                    </p>
                    <p className="article__para">
                    <strong className="titles-ps-strong-n"> 01 </strong> Déﬁnissez des indicateurs de 
                    performance propres à votre activité
                    </p>
                    <p className="article__para">
                    <strong className="titles-ps-strong-n"> 02 </strong> Analysez vos résultats grâce 
                    à la génération de rapports d’activité
                    </p>
                    <p className="article__para">
                    <strong className="titles-ps-strong-n"> 03 </strong> Suivez l’achalandage, les ventes, 
                    et les taux de retour en temps réel
                    </p>
                    <p className="article__para">
                    <strong className="titles-ps-strong-n"> 04 </strong> 
                    Gardez un oeil sur la productivité de vos collaborateurs
                    & consolidez votre business en prenant de meilleures décisions !
                    </p>

                </div>


                <p className="article__belt">
                    {/* <h2 className="article__heading">DONNEZ DE LA PUISSANCE À VOTRE ENTREPRISE GRÂCE À NOS SOLUTIONS DE GESTION DE POINTS DE VENTE</h2>
                        <div className="article__body">

                            <p className="article__para">
                                    Plus qu’un système d’encaissement… <strong  className="titles-ps-strong">Precision Systems</strong>, le véritable nouveau cerveau de votre entreprise
                            </p>

                        </div> */}
                    <img className="article__belt-image w-70" src= { require("../../images/projects/precisionsystems/all-in-one.png") }
                        alt="mobile-apps" />
                     
                </p>


                

                <div className="article__body article__body--has-extra bg-red" style={{ paddingBottom: "23px"}}>

                        <aside className="article__extra">
                            <p className="article__para inuit-u-p0">
                                
                                    <img src={ require("../../images/projects/precisionsystems/fichier-14.png") } alt="" className="project__logo-psin" />
                            
                            </p>

                            <p className="article__para inuit-u-p0">
                                <span className="project__detail">PRECISION SYSTEMS.</span>
                                <span className="project__detail project__detail--emphasis">
                                    <a className="project__link cursor-pointer" href="https://precisionsystemshq.com/" title="PRECISION SYSTEMS" target="_blank">GESTION D’INVENTAIRE</a>
                                </span>
                                
                            </p>
                        </aside>
                       
                        <p>
                            <br />
                            <h1 className="article__heading titles-ps">GESTION <strong className="titles-ps-strong">D’INVENTAIRE</strong></h1>
                            <p className="article__para">
                                <b> Que vous ayez un ou plusieurs points de vente,   </b><br />
                                qu’il s’agisse d’une boutique, d’un café d’un restaurant ou d’un autre type de commerce, Grâce à une multitude de fonctionnalités  avancées, le POS
                            </p>
                        </p>
                        <p className="article__para">
                        vous permet une gestion complète de stock, comprenant à la fois :<br />
                                Le contrôle des niveaux.
                        </p>
                        <p className="article__para">
                        Les opérations d’inventaires.
                        </p>
                        <p className="article__para">
                        Le suivi des commandes.
                        </p>
                        <p className="article__para">
                        La gestion de la relation fournisseurs.
                        </p>
                        <p className="article__para">
                        La gestion et la maîtrise des réception et des transferts de marchandises entre vos ateliers, vos dépôts et vos points de ventes.
                        </p>
                    
                    </div>


                   


                    <p className="article__belt">
                    <h2 className="article__heading titles-ps">RÉSEAUX <strong className="titles-ps-strong">DE MAGASINS</strong></h2>
                    <img className="article__belt-image" src="/img/projects/precisionsystems/reseaux-de-magasins.png"
                        alt="mobile-apps" />

                        
                </p>

                <div className="article__body">

                    <p className="article__para">
                        Pour une entreprise installée sur différents points de vente, il est indispensable de garder un oeil sur l’état générale du business.
                    </p>

                    <p className="article__para">

                        Precision Systems permet d’effectuer une analyse en temps réel des performances en donnant aux utilisateur une visibilité parfaite sur tous les points de vente du réseau de magasins : <br />

                            - Achalandage : le trafic à l’intérieur du magasin <br />
                            - Panier moyen <br />
                            - Chiffre d'affaire (espèce, chèque ou TPE) <br />
                            - Contribution de chaque magasin au chiffre d'affaire global <br />
                            - Les écarts de stock (dépot, transport, point de vente, ...) <br />
                            - Les écarts de caisse <br />
                            - Les dépenses <br />
                            - Les prélèvements <br />
                            - Contribution de chaque produit au chiffre d'affaire global <br />
                            - Un rapprochement entre le stock théorique et le stock réel <br />
                            - La consommation de la matière première <br />
                            - Les crédits clients <br />
                            - Suivi des comptes des clients fidèles <br />

                    </p>

                   
                </div>

               

                <p className="article__belt">
                    <h1 className="article__heading titles-ps">GESTION <strong className="titles-ps-strong">DES EMPLOYÉS</strong></h1>
                    <img className="article__belt-image w-70" src={ require("../../images/projects/precisionsystems/ps-gestion-des-emploies_fr.png") } alt="mobile-apps" />
                </p>

                <div className="article__body">

                    <p className="article__para">
                        L’application mobile de l’interface Réseaux de magasins permet un maximum de proximité et de réactivité grâce à un système de notifications personnalisées. <br />
                        Une notification est envoyée dans les situations suivantes pour chacun des magasins :<br />
                            - Ouverture de caisse<br />
                            - Clôture de caisse<br />
                            - Prélèvement depuis une caisse<br />
                            - Stock critique de l'un des produits dans le stock magasin
                    </p>
                </div>


                <div className="article__body article__body--has-extra bg-bleu"  style={{ paddingBottom: "23px"}}>

                <aside className="article__extra-left">
                    <p className="article__para inuit-u-p0">
                        
                            <img src={ require("../../images/projects/precisionsystems/e-boutique_fr.png") } alt="" className="project__logo-psin" />
                    
                    </p>

                    <p className="article__para inuit-u-p0">
                        <span className="project__detail">PRECISION SYSTEMS.</span>
                        <span className="project__detail project__detail--emphasis">
                            <a className="project__link cursor-pointer" href="https://precisionsystemshq.com/" title="PRECISION SYSTEMS" target="_blank">E-boutique dédiée</a>
                        </span>
                        
                    </p>
                </aside>
                <div  >
                        <p>
                            <br />
                            <br />
                           
                            <h1 className="article__heading titles-ps"> Votre E-boutique sur <strong className="titles-ps-strong">Application Mobile</strong> </h1>
                            <p className="article__para">
                            
                                Profitez d’un point de vente virtuel supplémentaire !
                            </p>
                        </p>

                        <p className="article__para">
                        Precision Systems met à votre disposition une boutique en ligne, sur<br />
                        l’application mobile MyPrecision, instantanément conçue à partir de<br />
                        votre catalogue de produits et ou services.
                        </p>
                        <p className="article__para">
                        Présentez vos produits et services d’une manière plus ergonomique <br />
                        et attrayante et stimulez la croissance de vos ventes.<br />
                        </p>
                        <p className="article__para">
                        Faites vous une place dans le mobile de vos clients, et  créez une forte <br />
                        connexion avec votre audience en leur proposant des offres exclusives et<br />
                        une expérience de shopping personnalisée !<br />
                        </p>
                        <p className="article__para">
                        Détectez les clients à proximité de vos points de vente physiques et<br />
                        invitez les à venir proﬁter de vos bons plans.
                    
                        </p>


                        </div>
                </div>

                <p className="article__belt">
                    <h2 className="article__heading titles-ps">E-Commerce <strong className="titles-ps-strong">& autres Intégrations </strong></h2>
                    
                    <img className="article__belt-image w-70" src="/img/projects/precisionsystems/hero-ecommerce.png"
                        alt="mobile-apps" />
                </p>
              

                

               
                
                <hr style={{ backgroundColor: "white", height: "1px", borderTop: "1px solid white"}} />
                <br />

            </article>
            <Realisation/>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default PrecisionSystems
